var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.patientVisitMedicalData.Photos && _vm.patientVisitMedicalData.Photos.length > 0)?_c('div',[_c('div',{staticClass:"th-visit-wrp--child photos"},[_c('all-pictures-modal'),_c('div',_vm._l((_vm.patientVisitMedicalData.Photos),function(item,index){return _c('div',{key:index,staticClass:"th-visit-wrp--child---xray photos",on:{"click":function($event){return _vm.openPhotoDetails(item.PhotoId)}}},[_c('image-portrait',{attrs:{"propsphotoId":parseInt(item.PhotoId),"propsUrl":item.Base64Photo},on:{"fullScreenImageEmit":_vm.callFscreen}},[[_c('round-label-with-check',{staticClass:"th-visit-wrp--child---xray-lbl",attrs:{"initialStrProps":item.Position == 1
            ? 'GF' : item.Position == 2
            ? 'LS' :  item.Position == 3
            ? 'RS' : null,"checkSign":false,"initialDescStrProps":null,"labelAlert":item.Label == 1
            ? 3 : item.Label == 2
            ? 1 :  item.Label == 3
            ? 0 : null}})]],2)],1)}),0),_c('narrow-button',{attrs:{"buttonTextNarrow":_vm.patientVisitMedicalData.Photos.some((item)=> item.IsAnnotated===true) ? 'Edit Annotation':'Create  Annotation',"classNrwBtnArray":['th-narrow-button', 'text-uppercase', 'create-annotation']},on:{"narrowButtonEventEmit":_vm.openPhotoAnnotation}})],1),_c('photo-annotation-section',{attrs:{"photos":_vm.patientVisitMedicalData.Photos,"isVisible":_vm.isPhotoAnnotationSectionVisible},on:{"update:isVisible":(newValue)=>{this.isPhotoAnnotationSectionVisible=newValue}}})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }