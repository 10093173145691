<template>
  <div class="th-visit-wrp" v-if="patientVisitMedicalData">
    <div class="th-visit-wrp--child">
      <div v-if="patientVisitMedicalData.Photos && patientVisitMedicalData.Photos.length > 0"
           class="th-visit-wrp--child---picpos">
        <div v-for="(item, index) in patientVisitMedicalData.Photos" :key="index">
          <round-label-with-check
            :initialStrProps="item.Position == 1
              ? 'GF' : item.Position == 2
              ? 'LS' :  item.Position == 3
              ? 'RS' : null"
            :initialDescStrProps="item.Position == 1
            ? 'General Frontal' : item.Position == 2
            ? 'Left Side' :  item.Position == 3
            ? 'Right Side' : null"
            :labelAlert="item.Label == 1
              ? 3 : item.Label == 2
              ? 1 :  item.Label == 3
              ? 0 : item.Label == 0 ? 2 : null"
            :checkSign="item.HasFinalMarking"
          />
        </div>
      </div>
      <div class="th-visit-wrp--child---text">
        <div v-for="(item, index) in patientVisitMedicalData.Photos" :key="index">
          <p v-if="item.Comment">
            {{ item.Comment }}
          </p>
        </div>
      </div>
    </div>
    <hr class="th-visit-wrp--hr" style="margin-bottom: 14px;"/>
    <div class="th-visit-wrp--flagged">
      <div class="th-visit-wrp--flagged-wrp">
        <p>
          {{ $t('flagged all data', currentSelectedLanguage) }}
        </p>
        <div
          class="th-visit-wrp--flagged---checkbox"
          @click="editFlaggedData"
        >
          <b-icon
            v-if="patientVisitMedicalData ? patientVisitMedicalData.FlaggedData : false"
            icon="check2"
          />
        </div>
      </div>
    </div>
    <hr class="th-visit-wrp--hr"/>
    <patient-visit-photo-section/>
    <hr class="th-visit-wrp--hr"/>
    <patient-visit-data/>
    <hr class="th-visit-wrp--hr"/>
    <documents/>
  </div>
</template>

<script>
import {mapGetters, mapState} from 'vuex';
import RoundLabelWithCheck from '../../../../../atoms/roundLabelWithCheck.vue';
import NarrowButton from '../../../../../atoms/narrowButton.vue';
import PatientVisitData from './patientVisitData.vue';
import Documents from './documents.vue';
import PatientVisitPhotoSection from './patientVisitPhotoSection.vue';
import {
  EDIT_PATIENT_VISIT_FLAGGED_DATA_ACTION,
} from '../../../../../../store/modules/patients.module';

export default {
  name: 'patientVisitDetails',
  components: {
    NarrowButton,
    RoundLabelWithCheck,
    PatientVisitData,
    Documents,
    PatientVisitPhotoSection
  },
  computed: {
    ...mapState(
      {
        currentSelectedLanguage: (state) => state.currentSelectedLanguage,
      },
    ),
    ...mapGetters(
      {
        modalData: 'getModal',
        patientVisitMedicalData: 'getPatientVisitDetail',
      },
    ),
  },
  methods: {
    editFlaggedData() {
      this.$store.dispatch(EDIT_PATIENT_VISIT_FLAGGED_DATA_ACTION,
        {
          PatientVisitId: this.patientVisitMedicalData.PatientVisitId,
          FlaggedData: !this.patientVisitMedicalData.FlaggedData,
        });
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeWindow, true);
  },
};
</script>

<style lang="scss">
.visit-data {
  flex-wrap: wrap;
}

.th-visit-wrp {
  @include space($type: padding, $direction: top, $amount: 35px);
  @include space($type: padding, $direction: bottom, $amount: 23px);
  height: 100%;
  display: flex;
  flex-direction: column;
  cursor: default;

  &--child {
    display: flex;
    flex-direction: row;

    &---picpos {
      flex-basis: 50%;

      & > div {
        @include space($type: margin, $direction: bottom, $amount: 12px);

        &:last-child {
          @include space($type: margin, $direction: bottom, $amount: 19px);
        }
      }
    }

    &---text {
      flex-basis: 50%;

      & > p {
        @include font-source($openSans, 11px, rgba(30, 45, 62, 0.6), normal, 1.55, normal);
      }
    }

    &---xray {
      @include space($type: margin, $direction: bottom, $amount: 29px);
      width: 100%;

      &:nth-child(2) {
        @include space($type: margin, $direction: left, $amount: 18px);
        @include space($type: margin, $direction: right, $amount: 18px);
      }

      &-lbl {
        @include calculatePosition(absolute, 6%, 6%, -6%, -6%);
      }
    }

    &---prop {
      @include space($type: margin, $direction: bottom, $amount: 20px);
      flex-basis: 35%;

      & > h1 {
        text-transform: uppercase;
        @include font-source($openSans, 12px, $primary, 600, normal, normal);
        @include space($type: margin, $direction: bottom, $amount: 2px);
      }

      & > p {
        @include font-source($openSans, 14px, rgba(30, 45, 62, 0.6), normal, 1.21, normal);
        @include space($type: margin, $direction: bottom, $amount: 2px);
      }

      &:first-of-type {
        @include space($type: margin, $direction: left, $amount: 0);
      }

      &:last-of-type {
        @include space($type: margin, $direction: left, $amount: auto);
        display: flex;
        align-items: center;
        flex-basis: auto;
      }
    }

    &---half {
      &-block {
        & > h1 {
          text-transform: uppercase;
          @include font-source($openSans, 12px, $primary, 600, normal, normal);
          @include space($type: margin, $direction: bottom, $amount: 2px);
        }

        & > p {
          @include font-source($openSans, 14px, rgba(30, 45, 62, 0.6), normal, 1.21, normal);
          @include space($type: margin, $direction: bottom, $amount: 2px);
        }
      }

      &:last-child {
        @include space($type: margin, $direction: left, $amount: auto);
      }
    }
  }

  &--hr {
    @include border(1px, solid, rgba(30, 45, 62, 0.1), all);
    @include space($type: margin, $direction: top, $amount: 0);
    @include space($type: margin, $direction: bottom, $amount: 29px);
    width: 100%;
  }

  &--flagged {
    display: flex;
    flex-direction: column;
    margin-bottom: 14px;

    &-wrp {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    p {
      margin-bottom: 0;
      text-transform: uppercase;
      @include font-source($openSans, 12px, $primary, 600, normal, normal);
    }

    &---checkbox {
      color: $blood-bright;
      width: 25px;
      height: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      box-shadow: inset 0 1px 3px 1px rgba(30, 45, 62, 0.1);
      background-color: $white;
      cursor: pointer;
      margin-left: 1.5em;

      & > svg {
        font-size: 24px;
      }
    }
  }
}
</style>
