var render = function render(){var _vm=this,_c=_vm._self._c;return _c('modal',{attrs:{"name":"allPicturesModal","width":"100%","height":"100%","classes":"picture-wrp"}},[_c('div',{attrs:{"slot":"top-right"},slot:"top-right"},[_c('b-icon',{staticClass:"closeBtn",attrs:{"icon":"x"},on:{"click":function($event){return _vm.$modal.hide('allPicturesModal')}}})],1),_c('swiper',{staticClass:"swiper",attrs:{"options":_vm.swiperOption}},[_vm._l((_vm.patientVisitMedicalData.Photos),function(item,index){return (_vm.patientVisitMedicalData.Photos)?_c('div',{key:index,staticClass:"th-visit-wrp--child---xray"},[_c('swiper-slide',[_c('image-portrait',{class:_vm.patientVisitMedicalData.Photos.length === 1
           ? 'oneImage' : _vm.patientVisitMedicalData.Photos.length === 2 ? 'twoImages' : null,attrs:{"showFullscreenButton":false,"propsUrl":item.Base64Photo},on:{"fullScreenImageEmit":_vm.callFscreen}},[[_c('round-label-with-check',{staticClass:"th-visit-wrp--child---xray-lbl",attrs:{"initialStrProps":item.Position === 1
            ? 'GF' : item.Position === 2
            ? 'LS' :  item.Position === 3
            ? 'RS' : null,"checkSign":false,"initialDescStrProps":null,"labelAlert":item.Label === 1
            ? 3 : item.Label === 2
            ? 1 :  item.Label === 3
            ? 0 : null}})]],2)],1)],1):_vm._e()}),_c('div',{staticClass:"swiper-scrollbar",attrs:{"slot":"scrollbar"},slot:"scrollbar"})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }