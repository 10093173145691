var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.patientVisitMedicalData)?_c('div',{staticClass:"th-visit-wrp"},[_c('div',{staticClass:"th-visit-wrp--child"},[(_vm.patientVisitMedicalData.Photos && _vm.patientVisitMedicalData.Photos.length > 0)?_c('div',{staticClass:"th-visit-wrp--child---picpos"},_vm._l((_vm.patientVisitMedicalData.Photos),function(item,index){return _c('div',{key:index},[_c('round-label-with-check',{attrs:{"initialStrProps":item.Position == 1
            ? 'GF' : item.Position == 2
            ? 'LS' :  item.Position == 3
            ? 'RS' : null,"initialDescStrProps":item.Position == 1
          ? 'General Frontal' : item.Position == 2
          ? 'Left Side' :  item.Position == 3
          ? 'Right Side' : null,"labelAlert":item.Label == 1
            ? 3 : item.Label == 2
            ? 1 :  item.Label == 3
            ? 0 : item.Label == 0 ? 2 : null,"checkSign":item.HasFinalMarking}})],1)}),0):_vm._e(),_c('div',{staticClass:"th-visit-wrp--child---text"},_vm._l((_vm.patientVisitMedicalData.Photos),function(item,index){return _c('div',{key:index},[(item.Comment)?_c('p',[_vm._v(" "+_vm._s(item.Comment)+" ")]):_vm._e()])}),0)]),_c('hr',{staticClass:"th-visit-wrp--hr",staticStyle:{"margin-bottom":"14px"}}),_c('div',{staticClass:"th-visit-wrp--flagged"},[_c('div',{staticClass:"th-visit-wrp--flagged-wrp"},[_c('p',[_vm._v(" "+_vm._s(_vm.$t('flagged all data', _vm.currentSelectedLanguage))+" ")]),_c('div',{staticClass:"th-visit-wrp--flagged---checkbox",on:{"click":_vm.editFlaggedData}},[(_vm.patientVisitMedicalData ? _vm.patientVisitMedicalData.FlaggedData : false)?_c('b-icon',{attrs:{"icon":"check2"}}):_vm._e()],1)])]),_c('hr',{staticClass:"th-visit-wrp--hr"}),_c('patient-visit-photo-section'),_c('hr',{staticClass:"th-visit-wrp--hr"}),_c('patient-visit-data'),_c('hr',{staticClass:"th-visit-wrp--hr"}),_c('documents')],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }