<template>
  <b-input-group v-if="rerend" class="th-input-filter">
    <div class="th-input-filter--date" v-click-outside="closeFromOutside">
      <simple-input
        ref="dateRef"
        v-if="rerend"
        class="namess"
        :placeholder-text-simple-text="calendarConfigs.placeholder"
        :read-only-props="calendarConfigs.readonly"
        @click.native="openedDatePicker"
        @keyUpSimpleInputEvent="setDate"
        v-maska="'####/##/##'"
      />
      <FunctionalCalendar
        v-show="shouldShowModal"
        ref="me"
        v-model="calendarData"
        :configs="calendarConfigs"
      >
        <template v-slot:footer>
          <button-custom buttonSize="xs"
                         @buttonEventEmit="clearSelectedDate" buttonText="Clear Date" />
          <button-custom buttonSize="xs"
                         @buttonEventEmit="TodaySelectedDate" buttonText="Today" />
          <button-custom  buttonSize="xs"
                          @buttonEventEmit="applyDate" buttonText="Apply" />
        </template>
      </FunctionalCalendar>
<!--      <date-picker
        ref="me"
        @datepicker-opened="openedDatePicker"
        @datepicker-closed="closeDatePicker"
        @date-applied="onInputCalendarSet"
        :language="currentSelectedLanguage"
        :date-input="dateInputProp"
        v-click-outside="hidePopup"
        :show-helper-buttons="true"
        :disabled-dates="disabledDates"
      />
      <b-icon
        v-if="showClearBtn"
        class="closeBtn"
        icon="x-circle"
        @click="clearDate"
      />
    </div>-->
<!--    <b-form-datepicker
      v-else
      @input="onInputCalendarSet"
      :locale="currentSelectedLanguage"
      class="th-input-filter&#45;&#45;calendar"
      :placeholder="calendarInputPlaceholder"
      :reset-button="true"
      v-click-outside="hidePopup"
    />-->
    </div>
  </b-input-group>
</template>

<script>
import { mapState } from 'vuex';
import { FunctionalCalendar } from 'vue-functional-calendar';
import moment from 'moment';
import SimpleInput from './simpleInput.vue';
import ButtonCustom from './button.vue';

export default {
  name: 'inputCalendar',
  components: {
    ButtonCustom,
    SimpleInput,
    FunctionalCalendar,
  },
  props: {
    localFilter: {
      type: Boolean,
      default: false,
    },
    calendarInput: {
      type: Boolean,
      default: true,
    },
    calendarInputPlaceholder: {
      type: String,
      default: 'Search date',
    },
    calendarResetButton: {
      type: Boolean,
      default: true,
    },
    showClearBtn: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      rerend: false,
      calendarData: {},
      d: null,
      calendarConfigs: {
        disabledDates: ['afterToday'],
        changeYearFunction: false,
        placeholder: this.calendarInputPlaceholder,
        readonly: false,
        isDateRange: true,
        changeMonthFunction: false,
        dateFormat: 'yyyy/mm/dd',
      },
      /* dateInputProp: {
        placeholder: this.calendarInputPlaceholder,
        readonly: true,
        inputClass: 'th-input-filter--calendar',
      }, */
      shouldShowModal: false,
      modalOutsideClose: true,
      /* disabledDates: {
        from: new Date(Date.now()),
      }, */
    };
  },
  computed: {
    ...mapState(
      {
        currentSelectedLanguage: (state) => state.currentSelectedLanguage,
      },
    ),
  },
  mounted() {
    this.eventListener();
    this.rerend = false;
    this.rerend = true;
  },
  methods: {
    matchDate(value) {
      const date = moment(value, 'YYYY/MM/DD', true);

      return date.isValid();
    },
    setDate(value) {
      if (this.matchDate(value)) {
        if (value < moment().add(1, 'days').format('YYYY/MM/DD')) {
          const object = {
            FirstDate: value,
            LastDate: value,
          };
          this.$refs.me.ChooseDate(value);
          /* this.$refs.dateRef.$children[0].localValue = value; */
          this.$emit('keyUpInputEventCalendar', object);
          this.hideCalendar();
        }
      }
    },
    eventListener() {
      window.addEventListener('keyup', (e) => {
        if (e.key === 'Backspace' && this.shouldShowModal && this.$refs.dateRef.$children[0].localValue === '') {
          this.$refs.dateRef.$children[0].blur();
          this.clearSelectedDate();
        } else if (e.key === 'Delete' && this.shouldShowModal) {
          this.$refs.dateRef.$children[0].blur();
          this.clearSelectedDate();
        } else if (this.$refs.dateRef !== undefined && this.$refs.dateRef.$children[0].localValue === '' && !this.localFilter) {
          this.$refs.dateRef.$children[0].blur();
          this.clearSelectedDate();
        }
      });
    },
    closeDatePicker() {
      this.shouldShowModal = false;
    },
    openedDatePicker() {
      this.shouldShowModal = true;
    },
    /* hidePopup() {
      if (this.shouldShowModal) {
        this.$refs.me.$children[0].$el.children[0].click();
      }
    }, */
    /* clearDate() {
      this.$emit('inheritClearButton');
      const a = document.getElementsByClassName('vdpr-datepicker__button-default');
      const b = document.getElementsByClassName('th-input-filter--calendar');
      if (a.length > 0) {
        a[0].click();
      }
      if (b.length > 0) {
        b[0].value = null;
      }
      this.onInputCalendarSet(null, null);
    }, */
    /* onInputCalendarSet(start, end) {
      const objectInput = {
        FirstDate: start,
        LastDate: end,
      };
      this.closeDatePicker();
      this.$emit('keyUpInputEventCalendar', objectInput);
    }, */
    clearSelectedDate() {
      const objectNullInput = {
        FirstDate: null,
        LastDate: null,
      };
      this.$refs.dateRef.$children[0].localValue = null;
      this.hideCalendar();
      this.resetDat();
      this.$emit('keyUpInputEventCalendar', objectNullInput);
    },
    applyDate() {
      const start = typeof this.calendarData.dateRange !== 'undefined'
        ? this.calendarData.dateRange.start : null;
      const end = typeof this.calendarData.dateRange !== 'undefined'
        ? this.calendarData.dateRange.end : null;
      const objectInput = {
        FirstDate: start,
        LastDate: end,
      };
      const formatSt = moment(start).format('YYYY/MM/DD');
      const formatEnd = moment(end).format('YYYY/MM/DD');
      this.$refs.dateRef.$children[0].localValue = start === end && start !== null && start !== ''
        ? `${formatSt}` : start !== null && start !== '' && formatEnd && formatEnd !== 'Invalid date' ? `${formatSt} - ${formatEnd}`
          : start !== null && start !== '' && formatEnd ? `${formatSt}` : null;
      this.hideCalendar();
      this.$emit('keyUpInputEventCalendar', objectInput);
    },
    resetDat() {
      if (typeof this.calendarData.dateRange !== 'undefined') {
        this.calendarData.dateRange = {
          start: '',
          end: '',
        };
      }
    },
    TodaySelectedDate() {
      this.$refs.me.ChooseDate('today');
      const formatted = moment(new Date()).format('YYYY/MM/DD');
      const objectTodayInput = {
        FirstDate: new Date(),
        LastDate: new Date(),
      };
      this.$refs.dateRef.$children[0].localValue = formatted;
      this.$emit('keyUpInputEventCalendar', objectTodayInput);
      this.hideCalendar();
      this.resetDat();
    },
    hideCalendar() {
      if (this.shouldShowModal) {
        this.closeDatePicker();
      }
    },
    closeFromOutside() {
      if(this.shouldShowModal) {
        this.closeDatePicker();
        this.resetDat();
      }
    }
  },
  beforeDestroy() {
    window.removeEventListener('keyup', this.eventListener, true);
  }
};
</script>

<style lang="scss">
.th-input-filter--date {
  width: 100%;
}
.vfc-main-container {
  z-index: 1000;
  position: absolute;
  width: 100%;
  .vfc-week .vfc-day span.vfc-span-day.vfc-hover:hover, .vfc-week .vfc-day span.vfc-span-day.vfc-hovered {
    background-color: $btn-primary-custom;
    color: $white;
  }
  .vfc-week .vfc-day span.vfc-span-day.vfc-marked:before {
    background-color: $primary;
  }
  .vfc-week .vfc-day .vfc-base-start, .vfc-week .vfc-day .vfc-base-end {
    background-color: $primary;
  }
  .vfc-week .vfc-day span.vfc-span-day.vfc-marked {
    background-color: $secondary;
    color: $white;
  }
  .vfc-week .vfc-day span.vfc-span-day.vfc-today {
    background-color: $danger;
  }
  .vfc-navigation-buttons div .vfc-arrow-left, .vfc-separately-navigation-buttons div .vfc-arrow-left {
    border-color: $neo-future;
  }
  .vfc-navigation-buttons div .vfc-arrow-right, .vfc-separately-navigation-buttons div .vfc-arrow-right {
    border-color: $neo-future;
  }

  .vfc-content {
    .vfc-top-date span {
      color: $neo-future;
    }
    .vfc-dayNames span {
      color: $neo-future;
    }
    .vfc-span-day {
      color: $neo-future;
    }

    & > div:last-child {

      div:last-child {
        display: none;
      }
    }
  }

  .footerCon {
    & > div:first-child {
      display: none;
    }
    justify-content: space-between;
  }
}
/*.vdpr-datepicker__calendar-button-helper {
  display: none;
}
.vdpr-datepicker__button--block {
  width: 50%;
  @include space(margin, left, auto);
}
.vdpr-datepicker__button {
  font-size: 12px;
}
.vdpr-datepicker__calendar-actions {
  font-size: 12px;
}
.vdpr-datepicker__calendar-table td {
  line-height: 20px;
  font-size: 10px;
}
.vdpr-datepicker__calendar-table thead {
  font-size: 9px;
}
.vdpr-datepicker__calendar-control {
  @include space(padding, all, 6px !important);
}
.vdpr-datepicker__calendar-control-prev, .vdpr-datepicker__calendar-control-next {
  @include space(padding, all, 9px !important);
}
.vdpr-datepicker__calendar-month-year {
  font-size: 10px;
}
.b-calendar-footer {
  .btn-outline-danger {
    color: $danger;
    border-color: $danger;

    &:hover, &:active {
      color: $white !important;
      border-color: $danger !important;
      background-color: $danger !important;
    }
    &:focus {
      box-shadow: $none !important;
    }
  }
}
.b-calendar-grid {
  &:focus {
    box-shadow: $none;
    border: 1px solid #ced4da;
  }
  .btn-outline-primary:not(:disabled) {
    box-shadow: $none;
  }
  .btn-primary:not(:disabled):not(.disabled).active {
    background-color: $primary;
    box-shadow: $none;

    &:focus {
      box-shadow: $none;
    }
  }

  .btn-outline-primary {
    color: $blood-bright;
    &:hover {
      background-color: $btn-primary-custom;
    }
    &:focus {
      box-shadow: $none !important;
    }
  }
}
.vdpr-datepicker__button-submit {
  background-color: $btn-primary-custom;
  border: none;

  &:hover {
    background-color: $btn-primary-custom;
  }
}
.vdpr-datepicker__calendar-table .highlighted {
  background-color: $btn-primary-custom;
  color: $white;

  &:hover {
    background-color: $btn-primary-custom;
  }
}
.closeBtn {
  position: absolute;
  cursor: pointer;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
}
.vdpr-datepicker__calendar-input-wrapper:first-child {
  display: none !important;
}
.th-input-filter--calendar {
  label {
    color: $primary !important;
  }
}
.th-input-filter {
  &--date {
    position: relative;
    height: 45px;
  }
  & > div:first-child {
    width: 100%;
  }
  .vdpr-datepicker {
    &:focus {
      outline: 0;
    }

    & > div {

      &:focus {
        outline: 0;
      }
    }
  }
  .vdpr-datepicker__calendar-dialog {
    width: 100%;
    right: 0;
  }
  .vdpr-datepicker__calendar {
    width: 100%;
  }
  .vdpr-datepicker__calendar-actions {
    width: 70%;
  }
  &--calendar {
    background-color: $white;
    @include space(padding, top, 14px !important);
    @include space(padding, right, 34px !important);
    @include space(padding, bottom, 14px !important);
    @include space(padding, left, 24px !important);
    @include border-radius(31px !important);
    @include border(1px, solid, rgba(30, 45, 62, 0.1));
    @include font-source($openSans, 14px !important, $primary, normal, 1.21px !important, 0.35px);
    height: 45px !important;
    width: 100%;

    &::placeholder {
      color: $primary;
    }

    &:focus {
      outline: 0;
    }

    & > button {
      display: $none;
    }

    & > label {
      line-height: 0;
      @include space(padding, left, 0 !important);
      min-height: 0 !important;
    }

    .dropdown-menu {
      transform: translate(0, 48px) !important;
    }
  }
}*/
</style>
