<template>
  <div class="th-tbwrp">
    <div class="label-element">
      <div
        v-if="patientList"
        :class="{ 'invisible': elementCondPropComp }"
      >
        <b-form-checkbox
          class="label-element-wrp"
          id="checkbox-patient-list"
          :checked="statusComputed == -1 || statusComputed == null"
          name="checkbox-patient-list"
          @change="filterButtonColor(-1)"
        />
        <p>
          {{ $t('All Labels', currentSelectedLanguage) }}
        </p>
      </div>
      <div
        v-else
        :class="{ 'invisible': elementCondPropComp }"
      >
        <b-form-checkbox
          class="label-element-wrp"
          id="checkbox-patient-visit"
          name="checkbox-patient-visit"
          :checked="statusComputedLocal == null"
          @change="filterButtonColor(null)"
        />
        <p>
          {{ $t('All Labels', currentSelectedLanguage) }}
        </p>
      </div>
    </div>
    <vue-scroll
      :ops="ops"
      ref="f"
      :class="{ 'd': true, 'invisible': elementCondPropComp }"
      v-if="patientList"
    >
      <b-table
        :class="{ 'th-tbwrp--table': true }"
        id="patientListTable1"
        :items="itemsPropsArray"
        :per-page="itemsPerpage"
        :fields="fieldsProps"
        :current-page="currentPageProps"
        selectable
        @row-selected="onRowSelected"
        select-mode="single"
        show-empty
        no-border-collapse
      >
        <template #head(label)="scope">
          <div class="d-flex align-items-center">
            {{ scope.label.toUpperCase() }}
            <div class="d-flex flex-column ml-1">
              <b-icon icon="chevron-up" class="cursor-pointer svg-sort"
                      @click="changeOrdering(2, 1)">
              </b-icon>
              <b-icon icon="chevron-down" class="cursor-pointer svg-sort"
                      @click="changeOrdering(2, 2)">
              </b-icon>
            </div>
          </div>
        </template>
        <template #cell(label)="scope">
          <div :class="{
            'mogo-icon-wrp': scope.item.Label === -1,
            'labellater-icon-wrp': scope.item.Label === 0,
            'norisk-icon-wrp': scope.item.Label === 1,
            'jomo-icon-wrp': scope.item.Label === 2,
            'cancer-icon-wrp': scope.item.Label === 3,
          }">
            <b-icon icon="person"/>
          </div>
        </template>
        <template #head(Id)="scope">
          <div class="d-flex align-items-center">
            {{ scope.label.toUpperCase() }}
            <div class="d-flex flex-column ml-1">
              <b-icon icon="chevron-up" class="cursor-pointer svg-sort"
                      @click="changeOrdering(1, 1)">
              </b-icon>
              <b-icon icon="chevron-down" class="cursor-pointer svg-sort"
                      @click="changeOrdering(1, 2)">
              </b-icon>
            </div>
          </div>
        </template>
        <template #cell(Id)="scope">
          <p style="font-weight: bold; display: inline-block; margin-bottom: 0">
            {{ scope.item.ListNumber }}
          </p>
          {{ scope.item.ClinicAccountId + ' ' + scope.item.Id }}
        </template>
        <template #head(DateOfLastVisit)="scope">
          <div class="d-flex align-items-center">
            {{ scope.label.toUpperCase() }}
            <div class="d-flex flex-column ml-1">
              <b-icon icon="chevron-up" class="cursor-pointer svg-sort"
                      @click="changeOrdering(4, 1)">
              </b-icon>
              <b-icon icon="chevron-down" class="cursor-pointer svg-sort"
                      @click="changeOrdering(4, 2)">
              </b-icon>
            </div>
          </div>
        </template>
        <template #cell(DateOfLastVisit)="scope">
          {{ moment(scope.item.DateOfLastVisit).format("YYYY-MM-DD") }}
        </template>
        <template #head(visit)="scope">
          <div class="d-flex align-items-center">
            {{ scope.label.toUpperCase() }}
            <div class="d-flex flex-column ml-1">
              <b-icon icon="chevron-up" class="cursor-pointer svg-sort"
                      @click="changeOrdering(6, 1)">
              </b-icon>
              <b-icon icon="chevron-down" class="cursor-pointer svg-sort"
                      @click="changeOrdering(6, 2)">
              </b-icon>
            </div>
          </div>
        </template>
        <template #cell(visit)="scope">
          {{ scope.item.Visits }}
        </template>
        <template #head(scans)="scope">
          <div class="d-flex align-items-center">
            {{ scope.label.toUpperCase() }}
            <div class="d-flex flex-column ml-1">
              <b-icon icon="chevron-up" class="cursor-pointer svg-sort"
                      @click="changeOrdering(5, 1)">
              </b-icon>
              <b-icon icon="chevron-down" class="cursor-pointer svg-sort"
                      @click="changeOrdering(5, 2)">
              </b-icon>
            </div>
          </div>
        </template>
        <template #cell(scans)="scope">
          {{ scope.item.Scans }}
        </template>
        <template #head(flaggedAmount)="scope">
          <div class="d-flex align-items-center">
            {{ scope.label.toUpperCase() }}
            <div class="d-flex flex-column ml-1">
              <b-icon icon="chevron-up" class="cursor-pointer svg-sort"
                      @click="changeOrdering(3, 1)">
              </b-icon>
              <b-icon icon="chevron-down" class="cursor-pointer svg-sort"
                      @click="changeOrdering(3, 2)">
              </b-icon>
            </div>
          </div>
        </template>
        <template #cell(flaggedAmount)="scope">
          {{ scope.item.FlaggedData }}
        </template>
        <template #head(IsAnnotated)>
          <div>
            Annotated
          </div>
        </template>
        <template #cell(IsAnnotated)="scope">
          <div>
            <b-form-checkbox class="annotated-checkbox" type="checkbox" v-model="scope.item.IsAnnotated" disabled/>
          </div>
        </template>
        <template #head(NeedsLabeling)>
          <div class="text-nowrap th-tbwrp--table-labelwrp">
            <div class="th-tbwrp--table-labelwrp-round gray" @click="filterButtonColor(0)">
              <b-icon v-if="statusComputed == 0" icon="check"/>
            </div>
            <div class="th-tbwrp--table-labelwrp-round green" @click="filterButtonColor(1)">
              <b-icon v-if="statusComputed == 1" icon="check"/>
            </div>
            <div class="th-tbwrp--table-labelwrp-round yellow" @click="filterButtonColor(2)">
              <b-icon v-if="statusComputed == 2" icon="check"/>
            </div>
            <div class="th-tbwrp--table-labelwrp-round red" @click="filterButtonColor(3)">
              <b-icon v-if="statusComputed == 3" icon="check"/>
            </div>
          </div>
        </template>
        <template #cell(NeedsLabeling)="scope">
          <custom-label v-if="scope.item.NeedsLabeling"/>
        </template>
      </b-table>
    </vue-scroll>
    <b-table
      v-else
      ref='selectableTable'
      id="patientListTable"
      :class="{ 'th-tbwrp--table': true, 's': true, 'invisible': elementCondPropComp }"
      show-empty
      :items="itemsPropsArray"
      :per-page="itemsPerpage"
      :current-page="currentPageProps"
      :fields="fieldsPatietProps"
      :filter="filterProps"
      selectable
      @row-clicked="onRowSelectedExpand"
      select-mode="single"
    >

      <template #empty>
        <div class="text-center  my-2">
          {{ $t('PatientVisitDontExist', currentSelectedLanguage) }}
        </div>
      </template>
      <template #head(RecordedMaxLabel)="scope">
        <div class="d-flex align-items-center">
          {{ scope.label.toUpperCase() }}
          <div class="d-flex flex-column ml-1">
            <b-icon icon="chevron-up" class="cursor-pointer svg-sort"
                    @click="filterAscendingStatus">
            </b-icon>
            <b-icon icon="chevron-down" class="cursor-pointer svg-sort"
                    @click="filterDescendingStatus">
            </b-icon>
          </div>
        </div>
      </template>
      <template #cell(RecordedMaxLabel)="scope">
        <div :class="{
            'mogo-icon-wrp': scope.item.RecordedMaxLabel == -1,
            'labellater-icon-wrp': scope.item.RecordedMaxLabel == 0,
            'norisk-icon-wrp': scope.item.RecordedMaxLabel == 1,
            'jomo-icon-wrp': scope.item.RecordedMaxLabel == 2,
            'cancer-icon-wrp': scope.item.RecordedMaxLabel == 3,
          }">
          <b-icon icon="person"/>
        </div>
      </template>
      <template #head(DateOfLastVisit)="scope">
        <div class="d-flex align-items-center">
          {{ scope.label.toUpperCase() }}
          <div class="d-flex flex-column ml-1">
            <b-icon icon="chevron-up" class="cursor-pointer svg-sort"
                    @click="filterAscendingDate"></b-icon>
            <b-icon icon="chevron-down" class="cursor-pointer svg-sort"
                    @click="filterDescendingDate"></b-icon>
          </div>
        </div>
      </template>
      <template #cell(DateOfLastVisit)="scope">
        {{ moment(scope.item.VisitDate).format("YYYY-MM-DD") }}
      </template>
      <template #head(FlaggedData)="scope">
        <div class="d-flex align-items-center">
          {{ scope.label.toUpperCase() }}
          <div class="d-flex flex-column ml-1">
            <b-icon icon="chevron-up" class="cursor-pointer svg-sort"
                    @click="filterAscendingFlaggedData"></b-icon>
            <b-icon icon="chevron-down" class="cursor-pointer svg-sort"
                    @click="filterDescendingFlaggedData"></b-icon>
          </div>
        </div>
      </template>
      <template #cell(FlaggedData)="scope">
        {{ scope.item.FlaggedData }}
      </template>
      <template #head(user)="scope">
        {{ scope.label.toUpperCase() }}
      </template>
      <template #cell(user)="scope">
        {{ scope.item.UserName }}
      </template>
      <template #head(comment)="scope">
        {{ scope.label.toUpperCase() }}
      </template>
      <template #cell(comment)="scope">
        {{ scope.item.CommentCount }}
      </template>
      <template #head(NeedsLabeling)>
        <div class="text-nowrap th-tbwrp--table-labelwrp">
          <div class="th-tbwrp--table-labelwrp-round gray" @click="filterButtonColor(0)">
            <b-icon v-if="statusComputedLocal == 0" icon="check"/>
          </div>
          <div class="th-tbwrp--table-labelwrp-round green" @click="filterButtonColor(1)">
            <b-icon v-if="statusComputedLocal == 1" icon="check"/>
          </div>
          <div class="th-tbwrp--table-labelwrp-round yellow" @click="filterButtonColor(2)">
            <b-icon v-if="statusComputedLocal == 2" icon="check"/>
          </div>
          <div class="th-tbwrp--table-labelwrp-round red" @click="filterButtonColor(3)">
            <b-icon v-if="statusComputedLocal == 3" icon="check"/>
          </div>
        </div>
      </template>
      <template #cell(NeedsLabeling)="scope">
        <custom-label v-if="scope.item.NeedsLabeling"/>
      </template>
      <template #row-details>
        <patient-visit-details/>
      </template>
    </b-table>
    <b-pagination
      v-if="patientList && rows !== 0"
      :class="{'justify-content-center': true, 'mt-2': true, 'invisible': elementCondPropComp}"
      v-model="cur"
      :total-rows="rows"
      @input="changePagination"
      aria-controls="patientListTable1"
      :per-page="itemsPerpage"
      :hide-goto-end-buttons="true"
    ></b-pagination>
    <!--    <b-pagination-->
    <!--      v-else-if="rows !== 0"-->
    <!--      class="justify-content-center"-->
    <!--      v-model="cur1"-->
    <!--      :total-rows="ariaRows && ariaRows.length > 0 ? ariaRows.length : 0"-->
    <!--      :per-page="itemsPerpage"-->
    <!--      aria-controls="patientListTable"-->
    <!--      :hide-goto-end-buttons="true"-->
    <!--    ></b-pagination>-->
  </div>
</template>

<script>
import moment from 'moment';
import {mapActions, mapGetters, mapState} from 'vuex';
import customLabel from '../../../../atoms/label.vue';
import PatientVisitDetails from './visit/patientVisitDetails.vue';
import {
  REMOVE_VISIT_DETAILS,
  SET_CURRENT_PAGE,
} from '../../../../../store/modules/patients.module';

export default {
  name: 'patientTable',
  components: {PatientVisitDetails, customLabel},
  props: {
    statusCondition: {
      type: [Number, String],
      default: -1,
    },
    elementCondProp: {
      type: Boolean,
      default: false,
    },
    statusConditionLocal: {
      type: [String, Number],
      default: null,
    },
    filterProps: {
      type: [String, Object],
      default: null,
    },
    itemsPerpage: {
      type: Number,
      default: 50,
    },
    patientList: {
      type: Boolean,
      default: true,
    },
    currentPageProps: {
      type: Number,
      default: 1,
    },
    fieldsProps: {
      type: Array,
      default: () => [
        {
          key: 'Label',
          label: 'Status',
        },
        {
          key: 'Id',
          label: 'Patient ID',
        },
        {
          key: 'DateOfLastVisit',
          label: 'Last Visit',
        },
        {
          key: 'visit',
          label: 'Visits',
        },
        {
          key: 'scans',
          label: 'Scans',
        },
        {
          key: 'flaggedAmount',
          label: 'Flagged Data',
        },
        {
          key: 'IsAnnotated',
          label: 'Is Annotated',
        },
        {
          key: 'NeedsLabeling',
        },
      ],
    },
    fieldsPatietProps: {
      type: Array,
      default: () => [
        {
          key: 'RecordedMaxLabel',
          label: 'Status',
        },
        {
          key: 'DateOfLastVisit',
          label: 'Date Of Visit',
        },
        {
          key: 'FlaggedData',
          label: 'Flagged Data',
        },
        {
          key: 'UserName',
          label: 'User',
        },
        {
          key: 'CommentCount',
          label: 'Comments',
        },
        {
          key: 'NeedsLabeling',
        },
      ],
    },
    itemsPropsArray: {
      type: Array,
      return: () => [],
    },
    itemsPaginationLength: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      cur: 1,//this.currentPageProps,
      // cur1: 1,
      ops: {
        vuescroll: {
          sizeStrategy: 'percentage',
          detectResize: false,
        },
        scrollPanel: {
          maxHeight: 800,
        },
        rail: {
          gutterOfEnds: null,
        },
        bar: {
          showDelay: 500,
          onlyShowBarOnScroll: false,
          keepShow: true,
          background: '#1e2d3e',
          opacity: 1,
          minSize: 0.35,
          specifyBorderRadius: false,
          size: '5px',
          disable: false,
        },
      },
    };
  },
  computed: {
    elementCondPropComp() {
      return this.elementCondProp;
    },
    statusComputedLocal() {
      return this.statusConditionLocal;
    },
    statusComputed() {
      return this.statusCondition;
    },
    rows() {
      if (this.itemsPaginationLength === 0) {
        this.ops.scrollPanel.maxHeight = 0;
      } else {
        this.ops.scrollPanel.maxHeight = 800;
      }
      return this.itemsPaginationLength;
    },
    moment() {
      return moment;
    },
    ...mapState(
      {
        currentSelectedLanguage: (state) => state.currentSelectedLanguage,
      },
    ),
    ...mapGetters(
      {
        pageNumber: 'getNumberPagination',
        getCurrentFilterData: 'getCurrentFilter',
      },
    ),
  },
  mounted() {
    setTimeout(() => {
      this.ops.bar.keepShow = true;
      this.resizeGutterForScroll();
      if (this.patientList) {
        this.cur = this.pageNumber ? this.pageNumber + 1 : 1;
        this.changePagination(this.pageNumber);
      }
    }, 1500);
  },
  methods: {
    async changeOrdering(column, direction) {
      await this.getAllPatients({
        ...this.getCurrentFilterData,
        sortBy: column,
        direction: direction
      });
    },
    filterAscendingFlaggedData() {
      this.itemsPropsArray.sort(function (a, b) {
        return b['FlaggedData'] - a['FlaggedData'];
      })
    },
    filterDescendingFlaggedData() {
      this.itemsPropsArray.sort(function (a, b) {
        return a['FlaggedData'] - b['FlaggedData'];
      })
    },
    filterAscendingDate() {
      this.itemsPropsArray.sort(function (a, b) {
        return new Date(b['VisitDate']) - new Date(a['VisitDate']);
      });
    },
    filterDescendingDate() {
      this.itemsPropsArray.sort(function (a, b) {
        return new Date(a['VisitDate']) - new Date(b['VisitDate']);
      });
    },
    filterDescendingStatus() {
      this.itemsPropsArray.sort(function (a, b) {
        return a['RecordedMaxLabel'] - b['RecordedMaxLabel'];
      });
    },
    filterAscendingStatus() {
      this.itemsPropsArray.sort(function (a, b) {
        return b['RecordedMaxLabel'] - a['RecordedMaxLabel'];
      });
    },
    resizeGutterForScroll() {
      const gutter = document.getElementsByClassName('__rail-is-vertical');
      if (gutter !== 'undefined' && gutter.length > 0) {
        const tableHead = document.querySelector('thead');
        this.ops.rail.gutterOfEnds = `${tableHead.offsetHeight}px`;
        /* gutter[0].style.bottom = '-64px'; */
      }
    },
    getDates(startDate, endDate) {
      const dates = [];
      let currentDate = startDate;
      const addDays = function (days) {
        const date = new Date(this.valueOf());
        date.setDate(date.getDate() + days);
        return date;
      };
      while (currentDate <= endDate) {
        dates.push(currentDate);
        currentDate = addDays.call(currentDate, 1);
      }
      return dates;
    },
    async changePagination(value) {
      this.$store.dispatch(SET_CURRENT_PAGE, value).then(() => {
        this.ops.bar.keepShow = false;
      });

      await this.getAllPatients({
        ...this.getCurrentFilterData,
        PageNumber: value === 0 ? 0 : value - 1
      });
    },
    filterButtonColor(value) {
      this.$emit('filterEmit', value);
    },
    onRowSelected(items) {
      this.$emit('onSelectedRowEmit', items);
    },
    onRowSelectedExpand(items, index) {
      this.$store.dispatch(REMOVE_VISIT_DETAILS);
      this.$emit('onSelectedRowEmitExpand', items, index);
    },
    ...mapActions({
      getAllPatients: 'getAllPatients'
    }),
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeGutterForScroll);
  }
};
</script>

<style lang="scss">
.svg-sort {
  padding: 2px;
}

.cursor-pointer {
  cursor: pointer;

  &:hover {
    fill: $danger;
  }
}

.b-table-empty-row {
  p {
    padding-top: 14px;
    padding-bottom: 14px;
  }
}

.__rail-is-vertical {
  bottom: 0 !important;
}

.s tbody td {
  border: none !important;
}

.s thead th {
  position: relative !important;
}

.annotated-checkbox{
  width: fit-content;
  margin: auto;
  input {
    &:focus {
      box-shadow: none;
    }
  }

  &:before, &:after {
    width: 20px;
    height: 20px;
    box-shadow: none !important;
    border-color: transparent !important;
  }
}

.label-element {
  display: flex;
  justify-content: flex-end;

  &-wrp {
    padding-right: .75rem;
    padding-left: .75rem;
  }

  & > div {
    display: flex;
    flex-direction: row-reverse;
    align-items: baseline;

    .custom-control-input {
      position: inherit !important;
    }

    label {
      height: 100%;

      input {
        &:focus {
          box-shadow: none;
        }
      }

      &:before, &:after {
        width: 20px;
        height: 20px;
        box-shadow: none !important;
        border-color: transparent !important;
      }
    }

    p {
      @include font-source($openSans, 12px, $primary, 600, normal, normal);
      margin-bottom: 0;
      margin-right: 5px;
    }
  }

  .custom-control-input:checked ~ .custom-control-label::before {
    background-color: $primary !important;
  }
}

.page-item.active .page-link {
  background-color: $primary;
  box-shadow: none !important;

  &:focus {
    box-shadow: none;
  }
}

.page-item.disabled .page-link {
  border-color: $transparent;
  background-color: $transparent;
  color: $primary;

  &:focus {
    box-shadow: none;
  }
}

.page-link {
  border-color: $transparent !important;
  background-color: $transparent;
  color: $primary;

  &:hover {
    background-color: $primary;
    color: $white;
  }

  &:focus {
    box-shadow: none;
  }
}

.table.b-table > tbody > .table-active {
  background-color: #ffffff;
  //box-shadow: $none;
  -webkit-box-shadow: 0 11px 0 0 rgba(255, 255, 255, 1);
  -moz-box-shadow: 0 11px 0 0 rgba(255, 255, 255, 1);
  box-shadow: 0 11px 0 0 rgba(255, 255, 255, 1);
}

.table.b-table > tbody > .table-active > td {
  background-color: $white;
}

.th-tbwrp--table {
  border-collapse: separate;
  border-spacing: 0 8px;
  margin-bottom: 0;
}

.th-tbwrp--table thead th {
  position: sticky;
  top: 0;
  z-index: 999;
  background-color: $jessy-jay;
  border: $none;
  @include font-source($openSans, 12px, $primary, 600, normal, normal);

  & > div {
    text-transform: uppercase;
  }
}

.th-tbwrp--table tbody tr {
  /* box-shadow: 0 13px 10px -10px $majestik-future;*/
  background-color: $white;

  &:focus {
    outline: $none;
  }
}

.th-tbwrp--table tbody tr:first-child td {
  border-top: none;
}

.th-tbwrp--table tbody tr:last-child td {
  border-bottom: none;
}

.th-tbwrp--table tbody td {
  border: $none;
  @include font-source($openSans, 14px, rgba(30, 45, 62, 0.6), normal, 1.21, normal);
  vertical-align: inherit;
  @include space(padding, top, 14px);
  @include space(padding, bottom, 14px);
  overflow: hidden;
  text-overflow: ellipsis;
  border: 8px solid $jessy-jay;
  border-right: 0;
  border-left: 0;
}

.th-tbwrp {
  scrollbar-width: none;
  display: flex;
  flex-direction: column;
  height: 100%;

  &::-webkit-scrollbar {
    overflow-y: scroll;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    width: 0;
    height: 0;
  }

  &--table {
    table-layout: fixed;
    color: $primary;

    &-labelwrp {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      &-round {
        cursor: pointer;
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        @include border-radius(50%);

        & > svg {
          color: $white;
          font-size: 25px;
        }
      }
    }
  }
}

.grey {
  background: $primary;
}

.gray {
  background-color: rgba(30, 45, 62, 0.1);
}

.green {
  background-color: $btn-primary-custom;
}

.yellow {
  background-color: $sunny-bright;
}

.red {
  background-color: $blood-bright;
}

.mogo-icon-wrp {
  background-color: $transparent;
  width: 30px;
  height: 30px;
  border: 2px solid rgba(30, 45, 62, 0.1);
  @include border-radius(50%);
  position: relative;

  & > svg {
    font-size: 19px;
    @include calculatePosition(absolute, 50%, 50%, -50%, -50%);
    color: rgba(30, 45, 62, 0.1);
  }
}

.jomo-icon-wrp {
  background-color: $sunny-transparent;
  width: 30px;
  height: 30px;
  @include border-radius(50%);
  position: relative;

  & > svg {
    font-size: 19px;
    @include calculatePosition(absolute, 50%, 50%, -50%, -50%);
    color: $sunny-bright;
  }
}

.labellater-icon-wrp {
  background-color: $btc-fancy;
  width: 30px;
  height: 30px;
  @include border-radius(50%);
  position: relative;

  & > svg {
    font-size: 19px;
    @include calculatePosition(absolute, 50%, 50%, -50%, -50%);
    color: $jessica-monique;
  }
}

.norisk-icon-wrp {
  background-color: $joe-green;
  width: 30px;
  height: 30px;
  @include border-radius(50%);
  position: relative;

  & > svg {
    font-size: 19px;
    @include calculatePosition(absolute, 50%, 50%, -50%, -50%);
    color: $btn-primary-custom;
  }
}

.cancer-icon-wrp {
  background-color: $bloody-transparent;
  width: 30px;
  height: 30px;
  @include border-radius(50%);
  position: relative;

  & > svg {
    font-size: 19px;
    @include calculatePosition(absolute, 50%, 50%, -50%, -50%);
    color: $blood-bright;
  }
}
</style>
