<template>
  <div class="th-drop-wrp" v-if="showDropdown">
    <v-select
      id="selectid"
      ref="d"
      :value="a"
      :options="selectedArray"
      :placeholder="placeHolderList"
      :loading="loadingIcon"
      :clearable="clearAbleFunctionalityProp"
      :label="labelTextDisplayProp"
      :multiple="multipleprops"
      @input="setSelected"
      class="th-drop-wrp--input"
      :searchable="searcAbilityProps"
    >
      <span slot="no-options">
        <div class="mb-2 mt-2">
          {{ dropDownInputNoData }}
        </div>
      </span>
    </v-select>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'dropdownInput',
  props: {
    clearAbleFunctionalityProp: {
      type: Boolean,
      default: false,
    },
    searcAbilityProps: {
      type: Boolean,
      default: false,
    },
    multipleprops: {
      type: Boolean,
      default: false,
    },
    labelTextDisplayProp: {
      props: {
        type: String,
        default: 'label',
      },
    },
    dropDownInputNoData: {
      type: String,
      default: 'custom placeholder not found',
    },
    placeHolderList: {
      type: String,
      default: 'Select user',
    },
    showDropdown: {
      type: Boolean,
      default: true,
    },
    loadingIcon: {
      type: Boolean,
      default: false,
    },
    searchAsynch: {
      type: Boolean,
      default: false,
    },
    selectedArray: {
      type: Array,
      default: () => [],
    },
    currentValue: {
      type: [String, Number, Array],
      default: null,
    },
    resetValueIfSelectAll: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      a: this.currentValue,
    };
  },
  computed: {
    ...mapState(
      {
        currentSelectedLanguage: (state) => state.currentSelectedLanguage,
      },
    ),
  },
  methods: {
    setSelected(value) {
      if (value.label === `${this.$t('All', this.currentSelectedLanguage)}`
          && this.resetValueIfSelectAll) {
        this.a = null;
      } else {
        this.a = value;
      }
      this.$emit('inputSelected', value);
    },
  },
};
</script>

<style lang="scss" scoped>
.th-drop-wrp .vs__dropdown-toggle .vs__selected-options > input {
  width: auto;
}
.v-select::v-deep {
  .vs__dropdown-menu {
    top: calc(100% - (-8px));
    padding-top: 8px;
    padding-bottom: 8px;
  }
}
.tt {
  .vs__selected {
    margin-bottom: 3px !important;
    position: relative;
  }
  .vs__selected-options > input {
    display: $none;
  }
}
.vs__selected-options {
  display: table;
}
.vs__deselect {
  display: inline-table;
  position: absolute;
  right: 2px;
  top: 47%;
  transform: translateY(-50%);
  & > svg {
    transform: scale(0.8);
  }
}
.th-drop-wrp .vs__dropdown-toggle .vs__actions {
  cursor: default;
  @include space($type:margin, $direction:left, $amount:auto !important);
}
.vs__selected {
  @include space($type:padding, $direction:top, $amount:1.5px !important);
  @include space($type:padding, $direction:right, $amount:16px !important);
  @include space($type:padding, $direction:bottom, $amount:1.5px !important);
  @include space($type:padding, $direction:left, $amount:3px !important);
  @include space($type:margin, $direction:bottom, $amount:0px !important);
  font-size: 10px;
  line-height: 17px;
  display: table;
  &:first-child {
    @include space($type:margin, $direction:bottom, $amount:0px !important);
  }
  &:not(:first-child) {
    @include space($type:margin, $direction:left, $amount:0 !important);
    @include space($type:margin, $direction:top, $amount:2px !important);
  }
}
.vs__dropdown-toggle {
  @include space($type:padding, $direction:all, $amount:0);
}
.v-select .vs__dropdown-menu{
  .vs__dropdown-option--highlight  {
    background-color: $btn-primary-custom;
  }
  li {
    .active {
      background-color: #152e41;
    }
    &:hover {
      background-color: $btn-primary-custom;
    }
  }
}
.th-drop-wrp {
    width: 100%;
    .vs--open {
      @include border-radius-separate(
          31px !important, 31px !important, 31px !important, 31px !important
      );
    }
    &--input {
      background-color: $white;
      @include space(padding, top, 10px !important);
      @include space(padding, right, 18px !important);
      @include space(padding, bottom, 12px !important);
      @include space(padding, left, 24px !important);
      @include border-radius-separate(31px, 31px, 31px, 31px);
      @include border(1px, solid, rgba(30, 45, 62, 0.1));
      @include font-source($openSans, 14px !important, $primary, normal, 1.21px !important, 0.35px);
    }
    .vs__dropdown-toggle {
      border: $none;

      .vs__selected-options {
        @include space(padding, left, 0);

        .vs__selected {
          @include space(margin, all, 0);
          @include space(padding, all, 0);
          color: #1a2d40;
          font-family: "Open Sans", sans-serif;
          font-size: 14px;
          font-weight: normal;
          line-height: 1;
          letter-spacing: 0.35px;
        }

        & > input {
          color: $primary;
          @include space(margin, top, 0);
          @include space(padding, left, 0);
        }
      }

      .vs__actions {
        padding: 0;

        & > svg {
          fill: $primary;
          transform: scale(0.7);
        }

        & > button {
          fill: $primary;
        }
      }
    }
  }
</style>
