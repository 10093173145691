<template>
  <div>
    <div class="th-visit-wrp--child visit-data">
      <div class="th-visit-wrp--child---prop">
        <h1>
          {{ $t('Palpable mass in the breast', currentSelectedLanguage) }}
        </h1>
        <p v-if="patientVisitMedicalData.PalpableMassInTheBreast == null">
          {{ $t('NotSelected', currentSelectedLanguage) }}
        </p>
        <p v-else-if="patientVisitMedicalData.PalpableMassInTheBreast == 0">
          {{ $t('NO', currentSelectedLanguage) }}
        </p>
        <p v-else-if="patientVisitMedicalData.PalpableMassInTheBreast == 1">
          {{ $t('YesRight', currentSelectedLanguage) }}
        </p>
        <p v-else-if="patientVisitMedicalData.PalpableMassInTheBreast == 2">
          {{ $t('YesLeft', currentSelectedLanguage) }}
        </p>
        <p v-else-if="patientVisitMedicalData.PalpableMassInTheBreast == 3">
          {{ $t('YesBoth', currentSelectedLanguage) }}
        </p>
      </div>
      <div class="th-visit-wrp--child---prop">
        <h1>
          {{ $t('Pain in the breast', currentSelectedLanguage) }}
        </h1>
        <p v-if="patientVisitMedicalData.PainInTheBreast == null"
        >
          {{ $t('NotSelected', currentSelectedLanguage) }}
        </p>
        <p v-else-if="patientVisitMedicalData.PainInTheBreast == 0">
          {{ $t('NO', currentSelectedLanguage) }}
        </p>
        <p v-if="patientVisitMedicalData.PainInTheBreast == 1">
          {{ $t('YesRight', currentSelectedLanguage) }}
        </p>
        <p v-if="patientVisitMedicalData.PainInTheBreast == 2">
          {{ $t('YesLeft', currentSelectedLanguage) }}
        </p>
        <p v-if="patientVisitMedicalData.PainInTheBreast == 3">
          {{ $t('YesBoth', currentSelectedLanguage) }}
        </p>
      </div>
      <div class="th-visit-wrp--child---prop">
        <h1>
          {{ $t('AgeOfPatient', currentSelectedLanguage) }}
        </h1>
        <p v-if="(patientVisitMedicalData.AgeOfPatient == null
              || patientVisitMedicalData.AgeOfPatient === -1)"
        >
          {{ $t('NotSelected', currentSelectedLanguage) }}
        </p>
        <p v-else-if="patientVisitMedicalData.AgeOfPatient == 0">
          {{ $t('Less35', currentSelectedLanguage) }}
        </p>
        <p v-if="patientVisitMedicalData.AgeOfPatient == 1">
          {{ $t('Between35_50', currentSelectedLanguage) }}
        </p>
        <p v-if="patientVisitMedicalData.AgeOfPatient == 2">
          {{ $t('More50', currentSelectedLanguage) }}
        </p>
      </div>
      <div class="th-visit-wrp--child---prop">
        <h1>
          {{ $t('MenstrualCycle', currentSelectedLanguage) }}
        </h1>
        <p v-if="patientVisitMedicalData.MenstrualCycle == null"
        >
          {{ $t('NotSelected', currentSelectedLanguage) }}
        </p>
        <p v-else-if="patientVisitMedicalData.MenstrualCycle == 0">
          {{ $t('Premenopausal', currentSelectedLanguage) }}
        </p>
        <p v-if="patientVisitMedicalData.MenstrualCycle == 1">
          {{ $t('Perimenopausal', currentSelectedLanguage) }}
        </p>
        <p v-if="patientVisitMedicalData.MenstrualCycle == 2">
          {{ $t('Postmenopausal', currentSelectedLanguage) }}
        </p>
      </div>
      <div class="th-visit-wrp--child---prop">
        <narrow-button
          buttonTextNarrow="Edit Data"
          :classNrwBtnArray="['th-narrow-button', 'text-uppercase']"
          @narrowButtonEventEmit="openVisitAdditionalModal"
        />
      </div>
    </div>
    <hr class="th-visit-wrp--hr"/>
    <div class="th-visit-wrp--child visit-data">
      <div class="th-visit-wrp--child---prop">
        <h1>
          {{ $t('Left', currentSelectedLanguage) }}
        </h1>
        <p v-if="patientVisitMedicalData.BiRadsLeft == null"
        >
          {{ $t('NotSelected', currentSelectedLanguage) }}
        </p>
        <p v-else>
          {{ patientVisitMedicalData.BiRadsLeft }}
        </p>
      </div>
      <div class="th-visit-wrp--child---prop">
        <h1>
          {{ $t('Right', currentSelectedLanguage) }}
        </h1>
        <p v-if="patientVisitMedicalData.BiRadsRight == null"
        >
          {{ $t('NotSelected', currentSelectedLanguage) }}
        </p>
        <p v-else>
          {{ patientVisitMedicalData.BiRadsRight }}
        </p>
      </div>
      <div class="th-visit-wrp--child---prop">
        <narrow-button
          buttonTextNarrow="Edit Data"
          :classNrwBtnArray="['th-narrow-button', 'text-uppercase']"
          @narrowButtonEventEmit="openBiRadsModal"
        />
      </div>
    </div>
    <hr class="th-visit-wrp--hr"/>
    <div class="th-visit-wrp--child visit-data">
      <div class="th-visit-wrp--child---prop">
        <h1>
          {{ $t('Biopsy result', currentSelectedLanguage) }}
        </h1>
        <p v-if="patientVisitMedicalData.BiopsyResult == null"
        >
          {{ $t('NotSelected', currentSelectedLanguage) }}
        </p>
        <p v-else-if="patientVisitMedicalData.BiopsyResult == 0">
          {{ $t('Benign', currentSelectedLanguage) }}
        </p>
        <p v-else-if="patientVisitMedicalData.BiopsyResult == 1">
          {{ $t('LuminalA', currentSelectedLanguage) }}
        </p>
        <p v-else-if="patientVisitMedicalData.BiopsyResult == 2">
          {{ $t('LuminalB', currentSelectedLanguage) }}
        </p>
        <p v-else-if="patientVisitMedicalData.BiopsyResult == 3">
          {{ $t('LuminalHer2', currentSelectedLanguage) }}
        </p>
        <p v-else-if="patientVisitMedicalData.BiopsyResult == 4">
          {{ $t('Her2Positive', currentSelectedLanguage) }}
        </p>
        <p v-else-if="patientVisitMedicalData.BiopsyResult == 5">
          {{ $t('TripleNegative', currentSelectedLanguage) }}
        </p>
      </div>
      <div class="th-visit-wrp--child---prop">
        <h1>
          {{ $t('Tumorsite', currentSelectedLanguage) }}
        </h1>
        <p v-if="patientVisitMedicalData.TumorSite == null">
          {{ $t('NotSelected', currentSelectedLanguage) }}
        </p>
        <p v-else-if=" patientVisitMedicalData.TumorSite == 0">
          {{ $t('Left', currentSelectedLanguage) }}
        </p>
        <p v-else-if="patientVisitMedicalData.TumorSite == 1">
          {{ $t('Right', currentSelectedLanguage) }}
        </p>
        <p v-else-if="patientVisitMedicalData.TumorSite == 2">
          {{ $t('Both', currentSelectedLanguage) }}
        </p>
      </div>
      <div class="th-visit-wrp--child---prop">
        <h1>
          {{ $t('Tumorsize', currentSelectedLanguage) }}
        </h1>
        <p v-if="patientVisitMedicalData.TumorSize == null"
        >
          {{ $t('NotSelected', currentSelectedLanguage) }}
        </p>
        <p v-else-if="patientVisitMedicalData.TumorSize == 0">
          {{ $t('UpToOneCm', currentSelectedLanguage) }}
        </p>
        <p v-else-if="patientVisitMedicalData.TumorSize == 1">
          {{ $t('FromOneToTwoCm', currentSelectedLanguage) }}
        </p>
        <p v-else-if="patientVisitMedicalData.TumorSize == 2">
          {{ $t('TwoCmOrMore', currentSelectedLanguage) }}
        </p>
      </div>
      <div class="th-visit-wrp--child---prop">
        <h1>
          {{ $t('Tumor location', currentSelectedLanguage) }}
        </h1>
        <p v-if="patientVisitMedicalData.TumorLocation == null"
        >
          {{ $t('NotSelected', currentSelectedLanguage) }}
        </p>
        <p v-if="patientVisitMedicalData.TumorLocation == 0">
          {{ $t('Central', currentSelectedLanguage) }}
        </p>
        <p v-else-if="patientVisitMedicalData.TumorLocation == 1">
          {{ $t('UpperOuter', currentSelectedLanguage) }}
        </p>
        <p v-else-if="patientVisitMedicalData.TumorLocation == 2">
          {{ $t('UpperInner', currentSelectedLanguage) }}
        </p>
        <p v-else-if="patientVisitMedicalData.TumorLocation == 3">
          {{ $t('LowerOuter', currentSelectedLanguage) }}
        </p>
        <p v-else-if="patientVisitMedicalData.TumorLocation == 4">
          {{ $t('LowerInner', currentSelectedLanguage) }}
        </p>
      </div>
      <div class="th-visit-wrp--child---prop">
        <narrow-button
          buttonTextNarrow="Edit Data"
          :classNrwBtnArray="['th-narrow-button', 'text-uppercase']"
          @narrowButtonEventEmit="openBiopsyModal"
        />
      </div>
    </div>
  </div>
</template>
<script>
import {mapGetters, mapState} from 'vuex';
import {SET_MODAL_ACTION} from "../../../../../../store";
import NarrowButton from "../../../../../atoms/narrowButton";

export default {
  name: 'patientVisitData',
  components: {
    NarrowButton,
  },
  computed: {
    ...mapState({
        currentSelectedLanguage: (state) => state.currentSelectedLanguage,
      },
    ),
    ...mapGetters({
        patientVisitMedicalData: 'getPatientVisitDetail',
      },
    ),
  },
  methods: {
    async openVisitAdditionalModal() {
      await this.$store.dispatch(SET_MODAL_ACTION, {
        current: true,
        type: 'editRelated',
      });
    },
    async openBiopsyModal() {
      await this.$store.dispatch(SET_MODAL_ACTION, {
        current: true,
        type: 'editBiopsy',
      });
    },
    async openBiRadsModal() {
      await this.$store.dispatch(SET_MODAL_ACTION, {
        current: true,
        type: 'editBiRads',
      });
    },
  },
}
</script>
<style lang="scss">

</style>
