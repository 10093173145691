export const BiopsyResultEnum = {
  all: {
    label: 'All',
    value: null
  },
  notSelected: {
    label: 'NotSelected',
    value: -1
  },
  benign: {
    label: 'Benign',
    value: 0
  },
  luminalA: {
    label: 'LuminalA',
    value: 1
  },
  luminalB: {
    label: 'LuminalB',
    value: 2,
  },
  luminalHer2: {
    label: 'LuminalHer2',
    value: 3
  },
  her2Positive: {
    label: 'Her2Positive',
    value: 4
  },
  tripleNegative: {
    label: 'TripleNegative',
    value: 5
  }
};
export const BiRadsEnum = {
  all: {
    label: 'All',
    value: null
  },
  notSelected: {
    label: 'NotSelected',
    value: 0
  },
  one: {
    label: '1',
    value: 1
  },
  two: {
    label: '2',
    value: 2
  },
  three: {
    label: '3',
    value: 3
  },
  four: {
    label: '4',
    value: 4
  },
  five: {
    label: '5',
    value: 5
  },
  six: {
    label: '6',
    value: 6
  },
};
export const TumorSizeEnum = {
  all: {
    label: 'All',
    value: null
  },
  notSelected: {
    label: 'NotSelected',
    value: -1
  },
  upToOneCm: {
    label: 'UpToOneCm',
    value: 0
  },
  fromOneToTwoCm: {
    label: 'FromOneToTwoCm',
    value: 1
  },
  twoCmOrMore: {
    label: 'TwoCmOrMore',
    value: 2
  },
};
export const TumorSideEnum = {
  all: {
    label: 'All',
    value: null
  },
  notSelected: {
    label: 'NotSelected',
    value: -1
  },
  left: {
    label: 'Left',
    value: 0
  },
  right: {
    label: 'Right',
    value: 1
  },
  both: {
    label: 'Both',
    value: 2
  },
};
export const TumorLocationEnum = {
  all: {
    label: 'All',
    value: null
  },
  notSelected: {
    label: 'NotSelected',
    value: -1
  },
  central: {
    label: 'Central',
    value: 0
  },
  upperOuter: {
    label: 'UpperOuter',
    value: 1
  },
  upperInner: {
    label: 'UpperInner',
    value: 2
  },
  lowerOuter: {
    label: 'LowerOuter',
    value: 3
  },
  lowerInner: {
    label: 'LowerInner',
    value: 4
  },
};
export const PalpableMassInTheBreastEnum = {
  all: {
    label: 'All',
    value: null
  },
  notSelected: {
    label: 'NotSelected',
    value: -1
  },
  no: {
    label: 'NO',
    value: 0
  },
  yesRight: {
    label: 'YesRight',
    value: 1
  },
  yesLeft: {
    label: 'YesLeft',
    value: 2
  },
  yesBoth: {
    label: 'YesBoth',
    value: 3
  },
};
export const PainInTheBreastEnum = {
  all: {
    label: 'All',
    value: null
  },
  notSelected: {
    label: 'NotSelected',
    value: -1
  },
  no: {
    label: 'NO',
    value: 0
  },
  yesRight: {
    label: 'YesRight',
    value: 1
  },
  yesLeft: {
    label: 'YesLeft',
    value: 2
  },
  yesBoth: {
    label: 'YesBoth',
    value: 3
  },
};
export const PatientAgeEnum = {
  all: {
    label: 'All',
    value: null
  },
  notSelected: {
    label: 'NotSelected',
    value: -1
  },
  less35: {
    label: 'Less35',
    value: 0
  },
  between35_50: {
    label: 'Between35_50',
    value: 1
  },
  more50: {
    label: 'More50',
    value: 2
  },
};
export const FamilyHistoryOfBreastCancerEnum = {
  all: {
    label: 'All',
    value: null
  },
  no: {
    label: 'NO',
    value: false,
  },
  yes: {
    label: 'YES',
    value: true,
  }
}
export const MenstrualCycleEnum = {
  all: {
    label: 'All',
    value: null
  },
  notSelected: {
    label: 'NotSelected',
    value: -1,
  },
  premenopausal: {
    label: 'Premenopausal',
    value: 0,
  },
  perimenopausal: {
    label: 'Perimenopausal',
    value: 1,
  },
  postmenopausal: {
    label: 'Postmenopausal',
    value: 2,
  }
}
export const PositionEnum = {
  frontal: 1,
  left: 2,
  right: 3,
}





