<template>
  <div v-if="patientVisitMedicalData.Photos && patientVisitMedicalData.Photos.length > 0">
    <div class="th-visit-wrp--child photos">
      <all-pictures-modal/>
      <div>
        <div v-for="(item, index) in patientVisitMedicalData.Photos"
             :key="index"
             class="th-visit-wrp--child---xray photos"
             @click="openPhotoDetails(item.PhotoId)">
          <image-portrait
            :propsphotoId="parseInt(item.PhotoId)"
            :propsUrl="item.Base64Photo"
            @fullScreenImageEmit="callFscreen">
            <template>
              <round-label-with-check
                class="th-visit-wrp--child---xray-lbl"
                :initialStrProps="item.Position == 1
              ? 'GF' : item.Position == 2
              ? 'LS' :  item.Position == 3
              ? 'RS' : null"
                :checkSign="false"
                :initialDescStrProps="null"
                :labelAlert="item.Label == 1
              ? 3 : item.Label == 2
              ? 1 :  item.Label == 3
              ? 0 : null"
              />
            </template>
          </image-portrait>
        </div>
      </div>
      <narrow-button
        :buttonTextNarrow="patientVisitMedicalData.Photos.some((item)=> item.IsAnnotated===true) ? 'Edit Annotation':'Create  Annotation'"
        :classNrwBtnArray="['th-narrow-button', 'text-uppercase', 'create-annotation']"
        @narrowButtonEventEmit="openPhotoAnnotation"
      />
    </div>
    <photo-annotation-section :photos="patientVisitMedicalData.Photos"
                              :isVisible="isPhotoAnnotationSectionVisible"
                              @update:isVisible="(newValue)=>{this.isPhotoAnnotationSectionVisible=newValue}"/>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import ImagePortrait from '../../../../../atoms/imagePortrait.vue';
import RoundLabelWithCheck from '../../../../../atoms/roundLabelWithCheck.vue';
import AllPicturesModal from "./allPicturesModal";
import NarrowButton from "../../../../../atoms/narrowButton";
import PhotoAnnotationSection from "../../../../photoAnnotationSection";

export default {
  name: 'patientVisitPhotoSection',
  components: {
    ImagePortrait,
    RoundLabelWithCheck,
    AllPicturesModal,
    NarrowButton,
    PhotoAnnotationSection
  },
  computed: {
    ...mapGetters({
      patientVisitMedicalData: 'getPatientVisitDetail'
    })
  },
  methods: {
    async openPhotoDetails(e) {
      await this.$router.push({
        name: 'photoDetail',
        params: {
          allowOnlyWithPush: true,
          patientId: this.$route.params.patientId,
          imageId: e,
        },
      });
    },
    callFscreen() {
      this.$modal.show('allPicturesModal');
    },
    openPhotoAnnotation() {
      this.isPhotoAnnotationSectionVisible = true;
      console.log("this.isPhotoAnnotationSectionVisible ", this.isPhotoAnnotationSectionVisible)
    }
  },
  data() {
    return {
      isPhotoAnnotationSectionVisible: false,
    }
  }
}
</script>
<style lang="scss" scoped>
.photos {
  display: block;

  div {
    display: flex;
  }
}

.create-annotation {
  margin-left: auto;
  margin-bottom: 20px;
  display: block;
}
</style>
