<template>
  <div v-if="isVisible&&photo" class="photo-annotation-section">
    <div class="photo-annotation-grid">
      <photo-annotation :photo="photo" :annotation-coordinates="annotationCoordinates"/>
      <div class="labels-section">
        <div>
          <b-icon class="close-icon" @click="closePhotoAnnotation" icon="x"/>
          <div>
            <button class="photo-position"
                    v-for="(item,index) in photos"
                    :key="'photoPosition'+index"
                    @click="()=>changePhoto(item.Position)">
              {{
                item.Position === PositionEnum.frontal ? 'GF' :
                  item.Position === PositionEnum.right ? 'RS' :
                    item.Position === PositionEnum.left ? 'LS' : ''
              }}
            </button>
            <!--            <button @click="()=>changePhoto(2)">RS</button>-->
            <!--            <button @click="()=>changePhoto(3)">LS</button>-->
          </div>
        </div>
        <div class="annotation-names">
          <div v-for="(item, index) in photoAnnotations.Annotations"
               :key="'photoAnnotation'+index">
            <span @click="()=>changeAnnotation(item)">
              {{ `Annotation ${index + 1}` }}</span>
            <b-icon @click="()=>deleteAnnotation(index)" class="trash" icon="trash-fill"
                    aria-hidden="true"></b-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import photoAnnotation from "./photoAnnotation";
import {PositionEnum} from "../../core/helpers/variables/constants";
import {mapActions, mapGetters} from "vuex";

export default {
  name: 'photoAnnotationSection',
  props: {
    photos: {
      type: [],
      default: null
    },
    isVisible: {
      type: Boolean,
      default: false
    }
  },
  emits: ["update:isVisible"],
  components: {
    photoAnnotation
  },
  methods: {
    async changePhoto(photoPosition) {
      this.photo = this.photos.find(element => element.Position === photoPosition);
      await this.getPhotoAnnotations(this.photo.PhotoId);
    },
    closePhotoAnnotation() {
      this.$emit("update:isVisible", false);
    },
    changeAnnotation(annotation) {
      this.annotationCoordinates = annotation.Annotation
    },
    async deleteAnnotation(index) {
      //this.annotationCoordinates = [];
      await this.deletePhotoAnnotation(index);
    },
    ...mapActions({
      getPhotoAnnotations: 'getPhotoAnnotations',
      deletePhotoAnnotation: 'deletePhotoAnnotation',
    }),
  },
  computed: {
    ...mapGetters({
      photoAnnotations: "getPhotoAnnotations"
    }),
  },
  data() {
    return {
      photo: {},
      PositionEnum,
      annotationCoordinates: []
    }
  },
  watch: {
    async isVisible(newValue, oldValue) {
      if (newValue) {
        this.photo = this.photos[0];
        await this.getPhotoAnnotations(this.photo.PhotoId);
      } else {
        this.photo = null;
        //this.setisNewAnnotationCreating(false);////////////////
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.photo-annotation-section {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(30, 45, 62, 0.2);
  transition: opacity .3s ease;
}

.photo-annotation-grid {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: white;
  border: 2px solid black;
  height: fit-content;//calc(100vh - 100px);
  display: flex;
  width: 60%;
  margin: auto;
}

.labels-section {
  border-left: 2px solid black;
  //height: 100%;
  padding: 5px;
  min-width: 100px;
}

.close-icon {
  display: block;
  margin-left: auto;
  font-size: 25px;
  cursor: pointer;
}
.photo-position{
  margin: 5px;
  padding: 7px;
}
.annotation-names {
  display: block;

  & > div {
    display: flex;
    margin-top: 5px;
    font-size: 14px;
    color: black;

    &:hover {
      cursor: pointer;
    }
  }

  .trash {
    margin-left: auto;

    &:hover {
      color: red;
      transform: scale(1.5);
    }
  }
}

</style>
