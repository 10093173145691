<template>
<b-input-group class="th-simple-input-wrp">
  <b-input
    class="th-simple-input-wrp--input"
    :type="simpleInputType"
    :placeholder="placeholderTextSimpleText"
    v-model="simpleInputValueVmodel"
    @input="onSimpleInputChange"
    :readonly="readOnlyProps"
    autocomplete="off"
  />
</b-input-group>
</template>

<script>
export default {
  name: 'simpleInput',
  props: {
    simpleInputType: {
      type: String,
      default: 'text',
    },
    placeholderTextSimpleText: {
      type: String,
      default: 'custom placeholder props',
    },
    readOnlyProps: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      simpleInputValueVmodel: null,
    };
  },
  methods: {
    onSimpleInputChange() {
      this.$emit('keyUpSimpleInputEvent', this.simpleInputValueVmodel);
    },
  },
};
</script>

<style lang="scss" scoped>
.th-simple-input-wrp {
  &--input {
    background-color: $white;
    @include space(padding, top, 13px !important);
    @include space(padding, right, 28px !important);
    @include space(padding, bottom, 15px !important);
    @include space(padding, left, 22px !important);
    @include border-radius(31px !important);
    @include border(1px, solid, rgba(30, 45, 62, 0.1));
    @include font-source($openSans, 14px !important, false, normal, 1.21px !important, normal);
    height: 45px !important;

    &:focus {
      box-shadow: $none !important;
    }

    &::placeholder {
      color: $primary;
    }
  }
}
</style>
