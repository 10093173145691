<template>
  <modal
    name="allPicturesModal"
    width="100%"
    height="100%"
    classes="picture-wrp"
  >
    <div slot="top-right">
      <b-icon
        class="closeBtn"
        icon="x"
        @click="$modal.hide('allPicturesModal')"
      />
    </div>
    <swiper class="swiper" :options="swiperOption">
      <div v-if="patientVisitMedicalData.Photos"
           v-for="(item, index) in patientVisitMedicalData.Photos"
           :key="index" class="th-visit-wrp--child---xray">
        <swiper-slide>
          <image-portrait
            :class="patientVisitMedicalData.Photos.length === 1
             ? 'oneImage' : patientVisitMedicalData.Photos.length === 2 ? 'twoImages' : null"
            :showFullscreenButton="false"
            :propsUrl="item.Base64Photo"
            @fullScreenImageEmit="callFscreen"
          >
            <template>
              <round-label-with-check
                class="th-visit-wrp--child---xray-lbl"
                :initialStrProps="item.Position === 1
              ? 'GF' : item.Position === 2
              ? 'LS' :  item.Position === 3
              ? 'RS' : null"
                :checkSign="false"
                :initialDescStrProps="null"
                :labelAlert="item.Label === 1
              ? 3 : item.Label === 2
              ? 1 :  item.Label === 3
              ? 0 : null"
              />
            </template>
          </image-portrait>
        </swiper-slide>
      </div>
      <div class="swiper-scrollbar" slot="scrollbar"></div>
    </swiper>
  </modal>
</template>
<script>

import {mapGetters, mapState} from 'vuex';
import {Swiper, SwiperSlide} from 'vue-awesome-swiper';
import RoundLabelWithCheck from '../../../../../atoms/roundLabelWithCheck.vue';
import ImagePortrait from '../../../../../atoms/imagePortrait.vue';
import NarrowButton from '../../../../../atoms/narrowButton.vue';
import 'swiper/swiper.scss';

export default {
  name: 'allPicturesModal',
  components: {
    NarrowButton,
    ImagePortrait,
    RoundLabelWithCheck,
    Swiper,
    SwiperSlide,
  },
  methods:{
    callFscreen() {
      this.$modal.show('allPicturesModal');
    }
  },
  data() {
    return {
      swiperOption: {
        spaceBetween: 30,
        scrollbar: {
          el: '.swiper-scrollbar',
        },
      },
    };
  },
  computed: {
    ...mapState(
      {
        currentSelectedLanguage: (state) => state.currentSelectedLanguage,
      },
    ),
    ...mapGetters(
      {
        patientVisitMedicalData: 'getPatientVisitDetail',
      },
    ),
  }
}
</script>
<style lang="scss">
.oneImage {
  img {
    width: auto !important;
    height: 100% !important;
  }
}

.twoImages {
  img {
    object-fit: contain;
    height: 100% !important;
  }
}

.swiper-slide {
  width: 100%;
}
.vm--modal {
  background-color: $primary !important;
  height: 100% !important;

  .th-img-port-wrp {
    height: 100%;
  }

  .swiper-container {
    height: 100%;
  }

  .th-visit-wrp--child---xray {
    margin-top: 43px;
    margin-bottom: 63px;
  }

  .th-img-port-wrp---frame > img {
    height: auto;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
  }
}

</style>
