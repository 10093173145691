<template>
  <div class="th-label--flag text-center">
    <div>
      {{ labelingText }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'label-custom',
  props: {
    labelingText: {
      type: String,
      default: 'Needs labeling',
    },
  },
};
</script>

<style lang="scss" scoped>
  .th-label--flag {
    & > div {
      @include property-color(background, $btc-fancy);
      @include font-source($openSans, 11px, rgba(26, 45, 64, 0.6), normal, 1.55, 0.27);
      @include space($type:padding, $direction:top, $amount:1px);
      @include space($type:padding, $direction:right, $amount:2px);
      @include space($type:padding, $direction:bottom, $amount:1px);
      @include space($type:padding, $direction:left, $amount:2px);
    }
  }
</style>
