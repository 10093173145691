<template>
  <div>
    <label class="upload-report">
      Upload report
      <input type="file" accept=".pdf,.doc" ref="file" @change="handleFileUpload"
             @click="resetUploader"/>
    </label>
    <ul class="documents"
        v-if="Array.isArray(patientVisitMedicalData.Documents)
        && patientVisitMedicalData.Documents.length !== 0">
      <li class="document-item" v-for="(item, index) in patientVisitMedicalData.Documents"
          :key="item.DocumentId">
        <span v-on:click="downloadDocument(item)">{{ item.Name }}</span>
        <b-icon @click="deleteDocument(item, index)" icon="x"/>
      </li>
      <li v-if="documentError" class="documents-error">{{ documentError }}</li>
    </ul>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {SET_MODAL_ACTION} from "../../../../../../store";
import {saveAs} from 'file-saver';

export default {
  name: 'documents',
  computed: {
    ...mapGetters({
      patientVisitMedicalData: 'getPatientVisitDetail',
      documentError: 'getDocumentError',
    })
  },
  methods: {
    async handleFileUpload() {
      const doc = this.$refs.file.files[0];
      if (doc) {
        const reader = new FileReader();
        reader.readAsDataURL(doc);
        reader.onload = () => {
          this.addDocument(
            {
              Name: doc.name,
              Document: reader.result.split(',')[1],
              PatientVisitId: this.patientVisitMedicalData.PatientVisitId,
            });
        };
        reader.onerror = (error) => {
          console.log('Error: ', error);
        };
      }
    },
    downloadDocument(document) {
      const byteString = atob(document.Document);
      const buffer = new ArrayBuffer(byteString.length);
      const byteArray = new Uint8Array(buffer);

      for (let i = 0; i < byteString.length; ++i) {
        byteArray[i] = byteString.charCodeAt(i);
      }

      const blob = new Blob([buffer], {type: 'application/pdf'});
      saveAs(blob, document.Name);
    },
    resetUploader() {
      this.$refs.file.value = '';
    },
    async deleteDocument(item, documentIndex) {
      await this.$store.dispatch(SET_MODAL_ACTION, {
        current: true,
        type: 'deleteDocument',
        params: {documentId: item.DocumentId, documentIndex},
      });
    },
    ...mapActions({
      addDocument: 'addDocument'
    })
  }
}
</script>
<style lang="scss">
.upload-report {
  border-radius: 20px;
  background-color: #81c8bd;
  color: white;
  padding: 5px 10px;
  width: fit-content;
  font-size: 12px;
  cursor: pointer;
  margin-bottom: 20px;

  input {
    display: none;
  }
}

.documents {
  list-style: none;
  padding: 0;
}

.document-item {
  font-size: 12px;
  color: #81c8bd;
  margin-bottom: 5px;
  width: fit-content;
  cursor: pointer;

  &:hover {
    svg {
      visibility: visible;
      opacity: 1;
    }
  }

  svg {
    width: 17px;
    height: 17px;
    padding: 2px;
    color: #fff;
    margin-left: 5px;
    margin-bottom: 3px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    background-color: #6c757d;
    text-align: center;
    cursor: pointer;
    visibility: hidden;
    opacity: 0;
    transition: .2s;
    -webkit-transition: .2s;
  }
}

.documents-error {
  color: red;
  font-size: 12px;
}
</style>
