<template>
  <b-button
    :type="buttonTypeNarrow"
    :class="classNrwBtnArray"
    @click="callNarrowButton"
    :disabled="buttonStateNarrow"
    :size="buttonSizeNarrow"
  >
    {{ buttonTextNarrow }}
  </b-button>
</template>

<script>
export default {
  name: 'narrowButton',
  props: {
    buttonTextNarrow: {
      type: String,
      default: 'Default text',
    },
    buttonSizeNarrow: {
      type: String,
      default: 'xs',
    },
    buttonStateNarrow: {
      type: Boolean,
      default: false,
    },
    buttonTypeNarrow: {
      type: String,
      default: 'submit',
    },
    classNrwBtnArray: {
      type: Array,
      default() {
        return ['th-narrow-button'];
      },
    },
  },
  methods: {
    callNarrowButton() {
      this.$emit('narrowButtonEventEmit');
    },
  },
};
</script>

<style lang="scss" scoped>
  .th-narrow-button {
    background-color: rgba(30, 45, 62, 0.6) !important;
    @include border-radius(31px);
    border: $none;
    height: 18px;
    @include space(padding, top, 0);
    @include space(padding, right, 7px);
    @include space(padding, bottom, 1px);
    @include space(padding, left, 7px);
    @include font-source($openSans, 9px, $white, bold, 1.89px, normal);

    &:focus {
      box-shadow: $none !important;
    }
  }
  .th-narrow-button-select {
    background-color: $btn-primary-custom !important;
    @include border-radius(31px);
    border: $none;
    height: 17px;
    @include space(padding, top, 0);
    @include space(padding, right, 7px);
    @include space(padding, bottom, 1px);
    @include space(padding, left, 7px);
    @include font-source($openSans, 9px, $white, bold, 1.89px, normal);

    &:focus {
      box-shadow: $none !important;
    }
  }
</style>
