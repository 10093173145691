<template>
  <div v-if="photo" class="photo-annotation">
    <div class="annotation">
      <canvas
        @click="canvasClick"
        ref="myCanvas"
      ></canvas>
      <!--      @mousemove="canvasMouseMove"-->
      <img ref="img" @load="onImageLoad" :src="`data:image/jpg;base64,${photo.Base64Photo}`"/>
    </div>
    <button class="create-new-label-btn" @click="()=>setIsNewAnnotationCreating(true)"> Create new label</button>
    <div v-if="isNewAnnotationCreating" class="buttons-section">
      <button @click="undo">Undo</button>
      <button @click="clearCanvas">Clear</button>
      <button @click="()=>setIsNewAnnotationCreating(false)">Cancel</button>
      <button @click="()=>saveData()">Save</button>
    </div>
  </div>
</template>
<script>

import {mapActions} from "vuex";

export default {
  name: 'photoAnnotation',
  props: {
    photo: {
      type: Object,
      default: null
    },
    annotationCoordinates: {
      type: Array,
      default: null
    }
  },
  data() {
    return {
      coordinates: [],
      isNewAnnotationCreating: false
    };
  },
  watch: {
    photo() {
      this.clearCanvas()
    },
    isNewAnnotationCreating() {
      this.clearCanvas()
    },
    annotationCoordinates(newValue) {
      this.coordinates = newValue;
      this.drawAnnotation();
    }
  },
  methods: {
    ...mapActions({
      addPhotoAnnotation: 'addPhotoAnnotation'
    }),
    drawAnnotation() {
      let canvas = this.$refs.myCanvas;
      let ctx = canvas.getContext("2d");
      ctx.clearRect(0, 0, Number(canvas.width), Number(canvas.height));//canvas.width canvas.height
      ctx.beginPath();
      ctx.fillStyle = "#77cc66";
      ctx.strokeStyle = "#77cc66";
      ctx.lineWidth = 2;
      if (this.coordinates.length > 1) {
        for (let i = 0; i < this.coordinates.length; i++) {
          if (i !== this.coordinates.length - 1) {
            ctx.moveTo(this.coordinates[i].Left, this.coordinates[i].Top);
            ctx.lineTo(this.coordinates[i + 1].Left, this.coordinates[i + 1].Top);
            ctx.stroke();
          }
          ctx.fillStyle = "#77cc66";
          ctx.fillRect(this.coordinates[i].Left - 3, this.coordinates[i].Top - 3, 6, 6);
        }
      }
      if (this.coordinates.length > 0) {
        ctx.fillStyle = "#77cc66";
        ctx.fillRect(this.coordinates[0].Left - 6,
          this.coordinates[0].Top - 6, 12, 12);
      }
      ctx.closePath();
    },
    async saveData() {
      console.log(this.coordinates);
      if (this.coordinates.length > 3
      && this.coordinates[0].Left===this.coordinates[this.coordinates.length-1].Left
      && this.coordinates[0].Top===this.coordinates[this.coordinates.length-1].Top) {
        this.setIsNewAnnotationCreating(false);
        await this.addPhotoAnnotation({
          PhotoId: this.photo.PhotoId,
          Annotation: this.coordinates,
        });
      }
    },
    setIsNewAnnotationCreating(value) {
      this.isNewAnnotationCreating = value;
    },
    onImageLoad() {
      let canvas = this.$refs.myCanvas;
      let img = this.$refs.img;
      canvas.width = img.clientWidth;
      canvas.height = img.clientHeight;
    },
    undo() {
      if (this.coordinates.length > 0) {
        this.coordinates.pop();
        this.drawAnnotation();
      }
    },
    clearCanvas() {
      let canvas = this.$refs.myCanvas;
      let ctx = canvas.getContext("2d");
      ctx.clearRect(0, 0, Number(canvas.width), Number(canvas.height));
      this.coordinates = [];
      ctx.beginPath();
      // ctx.closePath();
    },
    canvasClick: function (event) {
      if (this.isNewAnnotationCreating && event.offsetX && event.offsetY) {
        if (this.coordinates.length >= 3
          && event.offsetX <= this.coordinates[0].Left + 6
          && event.offsetX >= this.coordinates[0].Left - 6
          && event.offsetY <= this.coordinates[0].Top + 6
          && event.offsetY >= this.coordinates[0].Top - 6) {
          this.coordinates.push({
            Left: this.coordinates[0].Left,
            Top: this.coordinates[0].Top,
          });
        } else {
          this.coordinates.push({
            Left: event.offsetX,
            Top: event.offsetY,
          });
        }

        let canvas = this.$refs.myCanvas;
        let ctx = canvas.getContext("2d");
        ctx.fillStyle = "#77cc66";
        ctx.strokeStyle = "#77cc66";
        ctx.lineWidth = 2;

        if (this.coordinates.length > 1) {
          ctx.moveTo(this.coordinates[this.coordinates.length - 2].Left,
            this.coordinates[this.coordinates.length - 2].Top);
          ctx.lineTo(this.coordinates[this.coordinates.length - 1].Left,
            this.coordinates[this.coordinates.length - 1].Top);
          ctx.stroke();
          ctx.fillRect(this.coordinates[this.coordinates.length - 1].Left - 3,
            this.coordinates[this.coordinates.length - 1].Top - 3, 6, 6);
        } else {
          ctx.fillRect(this.coordinates[0].Left - 6,
            this.coordinates[0].Top - 6, 12, 12);
        }
      }
    },
    // canvasMouseMove: function (event) {
    //   if (this.isNewAnnotationCreating && event.offsetX && event.offsetY) {
    //     let canvas = this.$refs.myCanvas;
    //     let ctx = canvas.getContext("2d");
    //     if (this.coordinates.length > 0) {
    //       ctx.beginPath();
    //       if (event.offsetX <= this.coordinates[0].Left + 4
    //         && event.offsetX >= this.coordinates[0].Left - 4
    //         && event.offsetY <= this.coordinates[0].Top + 4
    //         && event.offsetY >= this.coordinates[0].Top - 4) {
    //         ctx.fillStyle = "#77cc66";
    //         ctx.fillRect(this.coordinates[0].Left - 4, this.coordinates[0].Top - 4, 8, 8);
    //       } else //if(this.coordinates.length === 1)
    //       {
    //         ctx.fillStyle = "rgb(250,250,250,0)";
    //         ctx.fillRect(this.coordinates[0].Left - 4, this.coordinates[0].Top - 4, 8, 8);
    //         ctx.fillStyle = "#77cc66";
    //         ctx.fillRect(this.coordinates[0].Left - 2, this.coordinates[0].Top - 2, 4, 4);
    //       }
    //       ctx.closePath();
    //     }
    //   }
    //}
  },
}

</script>

<style lang="scss" scoped>
.photo-annotation{
  margin-left: auto;
  margin-right: auto;
}
.annotation {
  position: relative;
  height: fit-content;
  width: fit-content;
  margin: auto;

  canvas {
    position: absolute;
    background-color: transparent;
  }

  img {
    //width: 450px;
    //height: calc(100vh - 150px);
    //object-fit: contain;
  }
}
.create-new-label-btn{
  margin: 10px auto 10px auto;
}
.buttons-section {
  display: block;
  margin: 0 auto 10px auto;
}
</style>
