import { render, staticRenderFns } from "./photoAnnotation.vue?vue&type=template&id=2e2d31ec&scoped=true"
import script from "./photoAnnotation.vue?vue&type=script&lang=js"
export * from "./photoAnnotation.vue?vue&type=script&lang=js"
import style0 from "./photoAnnotation.vue?vue&type=style&index=0&id=2e2d31ec&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e2d31ec",
  null
  
)

export default component.exports